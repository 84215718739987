import "./styles.css";

import { CSVLink } from "react-csv"
import CSVReader from "react-csv-reader";
import React from "react";
import ReactDOM from "react-dom";
import alltricks from './alltricks.png';
import colissimo from './colissimo.png';

class ConverterCsv extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      importedData: [],
      exportedData: []
    }
  }

  // arrow function for bind
  loadedDatas = (data) => {
    this.setState({importedData: data});
    this.keepRequestedFields()
  }

  keepRequestedFields = () => {
    const { importedData } = this.state
    let exportedData = []
    let ordersNumber = []

    importedData.forEach((data) => {
      // Keep only one order number per file for COLISHIP
      if(!ordersNumber.includes(data[1])){
        exportedData.push(data.filter((value, index) => {
          ///////////////////////////////////////
          // ALL TRICKS FILE (keep only the requested fields) for COLISHIP File
          // 0: "Numéro de commande"
          // 1: "Mode de livraison"
          // 2: "Adresse de livraison : Prénom"
          // 3: "Adresse de livraison : Société"
          // 4: "Adresse de livraison : Rue"
          // 5: "Adresse de livraison : Code postal"
          // 6: "Adresse de livraison : Ville"
          // 7: "Adresse de livraison : Pays"
          // 8: "Adresse de livraison : Téléphone 2"
          ///////////////////////////////////////
          const requestedFields = [1,7,32,34,35,38,39,41,43]

          return requestedFields.includes(index)
        }))
        ordersNumber.push(data[1])
      }
    })

    this.setState({exportedData: exportedData})

    this.matchFieldsForExport()
  }

  matchFieldsForExport = () => {
    const { exportedData } = this.state

    exportedData.forEach((data) => {
      ///////////////////////////////////////
      // ALL TRICKS FILE
      // 0: "Numéro de commande"
      // 1: "Mode de livraison"
      // 2: "Adresse de livraison : Prénom"
      // 3: "Adresse de livraison : Société"
      // 4: "Adresse de livraison : Rue"
      // 5: "Adresse de livraison : Code postal"
      // 6: "Adresse de livraison : Ville"
      // 7: "Adresse de livraison : Pays"
      // 8: "Adresse de livraison : Téléphone 2"
      //
      // MATCH TO to COLISHIP FILE
      // 0: nom société (si présente) - nom prenom
      // 1: numero + voix
      // 2 cp
      // 3 commune
      // 4 code pays
      // 5 tel portable
      // 6 code produit(= livraison)
      // 7 poids
      // 8 ref-commande
      ///////////////////////////////////////
      const copyData = Array.from(data)
      // 0: nom société (si présente) -
      data[0] = (data[3]) ? `${data[3]} - `  : ""
      // nom prenom
      data[0] += (copyData[2]) ? copyData[2].toString().substring(0,32).toUpperCase() : "Nom Prénom non renseigné"
      // 1: numero + voix
      data[1] = (copyData[4]) ? copyData[4].toString().substring(0,35).toUpperCase() : "Adresse non renseignée"
      // 2 cp
      const cp = (copyData[5]) ? copyData[5] : "Code postal non renseigné"
      data[2] =  (cp.toString().length === 4) ? `0${cp}` : cp
      // 3 commune
      data[3] = (copyData[6]) ? copyData[6].toString().substring(0,35).toUpperCase() : "Commune non renseignée"
      // 4 code pays
      data[4] = (copyData[7]) ? this.convertToCountryCode(copyData[7]) : "Pays non renseigné"
      // 5 tel portable
      data[5] = (copyData[8]) ? `0${copyData[8]}` : "0000000000"
      // 6 code produit(= livraison)
      data[6] = (copyData[1]) ? this.convertDeliveryToProductCode(copyData[1]) : "Livraison non renseignée"
      // 7 poids
      data[7] = 100
      // 8 ref-commande
      data[8] = (copyData[0]) ? copyData[0].toString().substring(0,16) : "Réf commande non renseignée"
    })

    // Delete first row (header)
    exportedData.splice(0,1)

    this.setState({isLoaded: true, exportedData: exportedData})
  }

  convertDeliveryToProductCode = (str) => {
    let productCode

    // Corresponding code COLISHIP to delivery
    // DOM : Colissomo domicile sans signature
    // DOS : Colissomo domicile avec signature
    // CORE : retour france
    // BPR : point de retrait
    // A2P : relais commercant
    // COLI : tous les colis hors france

    switch(str){
      case "Express à domicile" || "Standard à domicile":
          productCode = 'DOM'
        break
      default:
          productCode = 'DOM'
    }

    return productCode
  }

  convertToCountryCode = (strCountry) => {
    let codeCountry

    switch(strCountry){
      case "France métropolitaine - Hors Corse":
        codeCountry = 'FR'
        break
      case "United Kingdom":
        codeCountry = 'UK'
        break
      default:
        codeCountry = 'FR'
    }

    return codeCountry
  }

  render() {
    const { isLoaded, exportedData } = this.state;

    const papaparseOptions = {
      header: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    let button

    if (isLoaded) {
      console.log(exportedData)
      const date = new Date();
      const now = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      button = <CSVLink data={exportedData} target="_blank" separator={";"} filename={`commandes-coliship-${now}.csv`} enclosingCharacter={``} className="download">Fichier CSV ColiShip</CSVLink>
    }

    return (
        <div className="container">
          <div className="card">
            <div className="card-body">
              <h1 className="title">Convertisseur</h1>
              <div className="logos">
                <img src={alltricks} className="logo"  alt="logo"/>
                <img src={colissimo} className="logo" alt="logo"/>
              </div>
              <CSVReader
                cssClass="react-csv-input"
                label="Fichier CSV Alltricks"
                onFileLoaded={this.loadedDatas}
                parserOptions={papaparseOptions}
              />
              {button}
            </div>
          </div>
        </div>
    );
  }

}

ReactDOM.render(<ConverterCsv />, document.getElementById("root"));
